import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { TeamnoteApiService } from '../api/teamnote-api.service';
import { TeamNoteGeneralConstant } from '../constants/general.constant';
import { TeamNoteLocalStorageKeyConstants } from '../constants/local-storage-key.constant';
import { PageUrlConstant } from '../constants/page-url.constant';
// import { WebclientLoginService } from '../login/webclient-login.service';
import { IdleTimeoutService } from '../utilities/idle-timeout/idle-timeout.service';
import { LocalStorageManagerService } from '../utilities/local-storage/local-storage-manager.service';
import { LoggerService } from '../utilities/logger/logger.service';
import { NotificationCenterService } from '../utilities/notification-center/services/notification-center.service';
import { TnDialogService } from '../utilities/tn-dialog/tn-dialog.service';
import { TnLoaderService } from '../utilities/tn-loader/tn-loader.service';
import { WatermarkService } from '../utilities/watermark/watermark.service';
import { AccountManagerService } from './services/account/account-manager.service';
import { DataManagerService } from './services/data/data-manager.service';
import { ModuleManagerService } from './services/module/module-manager.service';
import { SocketService } from './services/socket/socket.service';
import { AccountService } from '../account/account.service';
import { BaseRoutingService } from '../routing/base-routing.service';
import { UserContactService } from './services/data/user-contact/user-contact.service';

var addDays = require('date-fns/add_days');
import { TnNotificationService } from '../utilities/tn-notification/tn-notification.service';
import { SideNavService } from '../utilities/tn-side-nav/side-nav.service';
import { ModuleKeyDefinition } from '../constants/module.constant';
import { QuestionnaireService } from './questionnaire/questionnaire.service';
import { JobReportService } from './job-report/job-report.service';
import { DateService } from '../utilities/date/date.service';
import { WorkflowService } from './workflow/services/workflow.service';
import { TeamnoteConfigService } from '../configs/teamnote-config.service';
import {TeamNoteApiConstant} from '../constants/api.constant';
import { ImportantUsers } from '../constants/user.constant';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class WebclientService {

  private _isConnected: boolean = false;
  private _isReconnecting: boolean = false;
  private _isReLoggingin: boolean = false;

  isDevMode: boolean = false;
  chatRoomTestMessageCounts: number[] = [10, 50, 100, 500, 1000, 5000, 10000];

  reconnectingTimeout: any = null;
  reconnectingCount: number = 0;
  reconnectingThreshold: number = 20;
  reconnectingInterval: any = null;

  enable_important_users: boolean;
  enable_message_delete: boolean;

  display_user_fields_in_chat: any;
  enable_message_read_ticks: boolean;
  enable_message_star: boolean;
  enable_out_of_office: boolean;
  enable_out_of_office$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  FILE_SIZE_LIMIT: number = this._teamnoteConfigService.config.GENERAL.ATTACHMENT_SIZE_LIMIT;
  
  disable_in_app_forward: boolean;
  disable_in_app_copy: boolean;
  allow_encrypted_message: boolean;

  limit_no_device_user_individual_chat: boolean;
  limit_no_device_user_group_chat: boolean;
  warn_no_device_user_group_chat: boolean;
  
  display_no_device_user_indicator: boolean;
  contact_card_no_device_indicator: boolean;
  allow_self_message: boolean;

  reconnectionTimer: any = null;
  reconnectionStopCount: number = 0;
  reconnectionStopCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  
  isPageVisible: boolean = true;
  isPageVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  hasBeenActive: boolean = false;
  _navigator: any = window.navigator;

  constructor(
    private _accountManagerService: AccountManagerService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _socketService: SocketService,
    private _dataManagerService: DataManagerService,
    private _moduleManagerService: ModuleManagerService,
    private _tnLoaderService: TnLoaderService,
    private _tnDialogService: TnDialogService,
    private _teamnoteApiService: TeamnoteApiService,
    private _loggerService: LoggerService,
    private _idleTimeoutService: IdleTimeoutService,
    private _notificationCenterService: NotificationCenterService,
    private _accountService: AccountService,
    private _baseRoutingService: BaseRoutingService,
    private _tnNotificationService: TnNotificationService,
    private _sideNavService: SideNavService,
    private _watermarkService: WatermarkService,
    private _questionnaireService: QuestionnaireService,
    private _jobReportService: JobReportService,
    private _dateService: DateService,
    private _workflowService: WorkflowService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _userContactService: UserContactService
  ) {
    this._loggerService.debug("Setting socketService's functions content, avoid circular dependency");
    this._socketService.customConnectCallback = () => this.onConnectSuccessCallback();
    this._socketService.customErrorCallback = (error) => this.socketErrorCallback(error);
    this._socketService.unknownMessageCallback = (frame, isBinding) => this._dataManagerService.receiveMessageCallback(frame, isBinding);
    this._socketService.unknownPresenceCallback = (frame, isBinding) => this._dataManagerService.receivePresenceCallback(frame, isBinding);

    this._loggerService.debug("Setting teamnoteApiService.logoutWebClient function content, avoid circular dependency");
    this._teamnoteApiService.logoutWebClient = (isDisconnectByServer) => this.onLogoutWebClient(isDisconnectByServer);
  }

  tnDebugger() {
    return this._dataManagerService.tnDebugger();
  }

  onLoginSuccess() {
    this.clearReconnectingCounter();
    this._loggerService.debug("Login Success!");
    this._accountManagerService.storeLoginResponse(this._accountService.fullLoginResponse);
    let data = this._accountManagerService.fullLoginResponse;

    let originalUserId = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_ID);

    this._loggerService.debug("Set latest session_token, user_id, message_recall_period, message_edit_period to Cookies");
    let oneMonthFromNow = addDays(new Date(), 30);
    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN, data.session_token, oneMonthFromNow);
    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_ID, data.user.user_id, oneMonthFromNow);
    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.MESSAGE_RECALL_PERIOD, data.user.message_recall_period, oneMonthFromNow);
    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.MESSAGE_EDIT_PERIOD, data.user.message_edit_period, oneMonthFromNow);

    if (originalUserId != null && originalUserId !== data.user.user_id) {
      this._loggerService.debug("Newly logged in user is not the same as previous user, clear local settings...");
      this.removeLocalSettings();
    }

    this._loggerService.debug("Set User ID in accountManagerService");
    this._accountManagerService.userId = data.user.user_id;

    this._loggerService.debug("Setting up TeamNote Date format...");
    this._dateService.initTnDateFormats();

    this._loggerService.debug("Installing Emoji set...");
    // this._loggerService.debug("Installing Emoji set...");
    // this._emojiService.installAppEmojiSet();

    let setupSideNav = function() {
      this._loggerService.debug("Setting up side nav...");
      this._sideNavService.setUpSideNav();
    }
    this._loggerService.debug("Setting available modules...");
    this._moduleManagerService.setModules(data.user.module, setupSideNav.bind(this));

    // this._loggerService.debug("Setting up side nav...");
    // this._sideNavService.setUpSideNav();

    let connectToWebSocket = () => {
      // console.log('connectToWebSocket callback');
      this._loggerService.debug("Connecting to web socket...");
      this._socketService.connectToWebSocket(data.user.user_id, data.session_token);
    }

    if (this._teamnoteConfigService.config.NOTIFICATION.PLAY_NOTIFICATION_AUDIO) {
      // enabled PLAY_NOTIFICATION_AUDIO
      if (this._navigator.userActivation) {
        if (!this._navigator.userActivation.hasBeenActive) {
          if (!this._tnNotificationService.alertRef) {
            this._tnNotificationService.showAlert(
              null,
              "GENERAL.NO_INTERACTION_ALERT",
              null,
              "GENERAL.CONFIRM",
              () => {
                connectToWebSocket();
              }
            );
          }
        } else {
          connectToWebSocket();
        }
      } else {
        if (!this.hasBeenActive) {
          if (!this._tnNotificationService.alertRef) {
            this._tnNotificationService.showAlert(
              null,
              "GENERAL.NO_INTERACTION_ALERT",
              null,
              "GENERAL.CONFIRM",
              () => {
                connectToWebSocket();
              }
            );
          }
        } else {
          connectToWebSocket();
        }
      }
    } else {
      // disabled PLAY_NOTIFICATION_AUDIO, then connect to webSocket directly
      connectToWebSocket();
    }

    // this._loggerService.debug("Connecting to web socket...");
    // this._socketService.connectToWebSocket(data.user.user_id, data.session_token);

    // Idle Timeout
    if (data.user.session_timeout != -1) {
      this._loggerService.debug("Session timeout is enabled");
      this._idleTimeoutService.initIdleTimeout(data.user.session_timeout);
    }
  }

  removeLocalSettings() {
    let localSettingsKeys = TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES;
    for (let i in localSettingsKeys) {
      this._localStorageManagerService.removeCookiesByKey(localSettingsKeys[i]);
    }
    this._loggerService.debug("Remove local settings in cookies: " + _.values(localSettingsKeys).join(", "));
  }

  clearExtAuthCookies(): void {
    this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.EXTERNAL_AUTH.AUTH_TYPE);
    this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.EXTERNAL_AUTH.AUTH_NAME);
    this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.EXTERNAL_AUTH.OAUTH.OAUTH_TOKEN);
  }

  onConnectSuccessCallback() {
    this._loggerService.debug("Web socket is connected successfully, resetting local variables");
    this._isConnected = true;
    this._isReconnecting = false;
    this._isReLoggingin = false;
    this.clearReconnectingCounter();

    this._loggerService.debug("Getting modules initial contents...");
    this.getAllModuleInitialContent();

    
    this._teamnoteApiService.callApi( TeamNoteApiConstant.USER_FIELDS,{}, (resp)=> {
      this.display_user_fields_in_chat = resp.global && resp.global.display_user_fields_in_chat;
      this.enable_message_read_ticks = resp.global && resp.global.enable_message_read_ticks;
      this.enable_message_star = resp.global && resp.global.enable_message_star;
      this.enable_message_delete = resp.global && resp.global.enable_message_delete;
      this.disable_in_app_forward = resp.global && resp.global.disable_in_app_forward;
      this.disable_in_app_copy = resp.global && resp.global.disable_in_app_copy;
      this.allow_encrypted_message = resp.global && resp.global.allow_encrypted_message;
      
      this.limit_no_device_user_individual_chat = resp.global && resp.global.limit_no_device_user_individual_chat;
      this.limit_no_device_user_group_chat = resp.global && resp.global.limit_no_device_user_group_chat;
      this.warn_no_device_user_group_chat = resp.global && resp.global.warn_no_device_user_group_chat;
      this.display_no_device_user_indicator = resp.global && resp.global.display_no_device_user_indicator;
      this.contact_card_no_device_indicator = resp.global && resp.global.contact_card_no_device_indicator;

      this.allow_self_message = resp.global && resp.global.allow_self_message;
      
      this.enable_important_users = resp.global && resp.global.enable_important_users;
      // if enable_important_users then call api to get all important users
      this.enable_important_users && this.getImportantUsers(); 

      if (resp.global && resp.global.file_size_limit) {
        this.FILE_SIZE_LIMIT = Number(resp.global.file_size_limit)
      }
      
      this.enable_out_of_office = resp.global && resp.global.oof_scheduler;
      // this.updateOutOfOfficeSubject(this.enable_out_of_office);
    }, () =>{
    });

  }

  onLogoutWebClient(isDisconnectedByServer: boolean) {
    this._loggerService.debug("Logging out webclient..., isDisconnectedByServer = " + isDisconnectedByServer);

    // TODO: set session storage key 'domain' again?

    // Call logout to invalidate session
    if (!isDisconnectedByServer) {
      this._teamnoteApiService.callApi(TeamNoteApiConstant.LOGOUT, {
        device_token: this._localStorageManagerService.getDeviceToken(),
      }, (resp) => {
        this._loggerService.debug('Logout success');
      }, () => {
        this._loggerService.debug('Failed to logout');
      });
    }

    // Clear all route related cookies
    this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE_MODULE);
    this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE);
    this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE_META_DATA);

    // Remove session token and refresh token
    this._loggerService.debug("Remove session token and password in cookies");
    this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN);
    this._localStorageManagerService.removeLocalStorageByKey(TeamNoteLocalStorageKeyConstants.SESSION.PASSWORD);
    this._localStorageManagerService.removeSessionStorageByKey(TeamNoteLocalStorageKeyConstants.SESSION.REFRESH_TOKEN);

    if (!isDisconnectedByServer) {
      this._loggerService.debug("User logout manually, remove local settings, user_id, message_recall_period, message_edit_period in cookeis");

      // if manually logout, delete local settings & user_id
      this.removeLocalSettings();

      // if manually logout, delete device token
      this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.DEVICE_TOKEN);

      // remove user name by config
      if (this._teamnoteConfigService.config.WEBCLIENT.GENERAL.IS_REMOVE_SESSION_USER_NAME) {
        this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_NAME);
      }

      this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_ID);
      this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.MESSAGE_RECALL_PERIOD);
      this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.MESSAGE_EDIT_PERIOD);

      // Remove ext auth related cookies
      this.clearExtAuthCookies();

      this._loggerService.debug("Resetting login info in webclientLoginService...");
      // remove user_name and password in loginService
      // this.acc.resetLoginInfo();

      // remove all notifications
      this._notificationCenterService.clearAllNotifications();
    } else {
      this._loggerService.debug("Disconnected by server, setting disconnect time...");
      // if disconnected by server, save disconnet time = last active time
      this._socketService.setDisconnectTime();
    }

    // Close all modals
    this._loggerService.log("Closing all opened dialogs...");
    this._tnDialogService.closeAllDialogs();

    // Clear watermark setting
    this._loggerService.log("Clear watermark setting");
    this._watermarkService.initWatermarkSetting();

    // Reset side nav
    this._loggerService.debug("Resetting side navs");
    this._sideNavService.resetSideNavs();

    // reset chat loaded status
    this._loggerService.debug("Resetting chat loaded status");
    this._dataManagerService.updateIsChatLoaded(false);

    // reset info in account manager
    this._loggerService.debug("Resetting info in accountManagerService");
    this._accountManagerService.onLogout();

    // set isConnected = false
    this._loggerService.debug("Set webclientService._isConnected to false");
    this._isConnected = false;

    // Reset is need reconnect (to handle case where it was reconnecting before, but received access refuse and cannot relogin due to expired token. We need to reset it when we LOGOUT)
    this._socketService.realSetIsNeedReconnectFalse();

    // Disconnect socket
    this._loggerService.debug("Disconneting web socket...");
    this._socketService.disconnectSocket(isDisconnectedByServer);

    // Clear all data in data manager
    this._loggerService.debug("Resetting all data in dataManagerService");
    this._dataManagerService.resetAllData();

    // go to login page
    this._loggerService.log("Logout success! Redirecting to /login");
    this._baseRoutingService.goToLoginPage();
  }

  clearReconnectingCounter(): void {
    this.reconnectingCount = 0;
    clearInterval(this.reconnectingTimeout);
  }

  updatePageVisibleSubject(val: boolean): void {
    this.isPageVisible$.next(val);
  }

  updateReconnectionStopCountSubject(val: number): void {
    if (this.reconnectionStopCount !== null) {
      this.reconnectionStopCount = val
      this.reconnectionStopCount$.next(val);
    }
  }

  updateOutOfOfficeSubject(val: boolean): void {
    this.enable_out_of_office$.next(val);
  }

  // Socket
  socketErrorCallback(error) {
    this._loggerService.error("Socker error callback, checking if need reconnect or relogin");
    let isAccessRefused = false;
    if (typeof error.body != 'undefined') {
      isAccessRefused = error.body.indexOf(TeamNoteGeneralConstant.ACCESS_REFUSED_ERROR) !== -1;
    }
    // Debug
    // isAccessRefused = true;
    // console.log('isAccessRefused?', isAccessRefused, 'isPageVisible?', this.isPageVisible)
    if (!isAccessRefused) {
      this._loggerService.debug("Socket error is no Access Refused.");
      if (this._accountManagerService.userId && this._accountManagerService.accessToken) {
        this._loggerService.debug("User ID and session token exists, webclient was connected.");
        this._loggerService.debug("Disconnect from web socket and try to reconnect every 5 seconds...");
        // if it was connected & not refused by server, try to reconnect 
        // console.log('try to reconnect socket, is ws connected well?', this._socketService.getClient());
        this._socketService.disconnectSocket(this._isConnected);
        if (this._isConnected) {
          this._socketService.setIsNeedReconnectTrue();
          this._socketService.setDisconnectTime();
        }
        // this._socketService._isNeedReconnect = this._isConnected;
        // this._tnLoaderService.showSpinner('LOADING.RECONNECTING');

        if (!this._teamnoteConfigService.config.WEBCLIENT.GENERAL.IS_ALLOW_EXTEND_WS_CONNECTION_THRESHOLD && this.reconnectingCount >= this.reconnectingThreshold) {
          this._loggerService.debug("Logout after 20 retries...");
          this.clearReconnectingCounter();
          this.onLogoutWebClient(true);
          return;
        }

        clearTimeout(this.reconnectionTimer);
        this.reconnectionTimer = setTimeout(() => {
          this._loggerService.debug("Reconnecting to web socket...");
          if (!this._teamnoteConfigService.config.WEBCLIENT.GENERAL.IS_ALLOW_AUTO_EXTEND_SESSION) {
            this.reconnectingCount++;
          }

          this.reconnectionStopCount++
          this.updateReconnectionStopCountSubject(this.reconnectionStopCount);
          // console.log('reconnectionCount:', this.reconnectionStopCount)

          console.warn(this.reconnectingCount);
          
          // console.log('try to reconnect to WebSocket')
          this._socketService.connectToWebSocket(this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_ID), this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN));
        }, 5000);
      } else {
        this._loggerService.debug("User ID and session token don't exist, it was logged out before.");
        this._loggerService.debug("Call logout again.");
        this.onLogoutWebClient(true);
      }
    } else {
      console.log('Socket error is Access refused', isAccessRefused);
      this._loggerService.debug("Socket error is Access refused");
      this.clearReconnectingCounter();
      if (!this._isReLoggingin) {
        this._loggerService.debug("User is not in the middle of re-logging in, try to relogin webclient");
        this._isReLoggingin = true;
        // access refused = token expired, try to relogin if all login info exist
        // console.log('try to relogin webclient');
        this._teamnoteApiService.reLogin(
          () => {
            this._loggerService.debug("Relogin success, call login success process again");
            this.onLoginSuccess();
          },
          () => {
            this._loggerService.error("Relogin failed after socket error, logout webclient");
            this.onLogoutWebClient(true);
            this._tnNotificationService.showAlert(
              null,
              "GENERAL.SESSION_TIMEOUT_MSG",
              null,
              "GENERAL.CONFIRM",
              () => {
                location.reload();
              }
            );
          }
        );
      } else {
        this._loggerService.error("User failed socket connection twice already, logout webclient");
        // If user is already tring to re login (i.e. user failed twice), logout
        this.onLogoutWebClient(true);
      }
    }
    return false;
  }

  // Simulate socket connectivity
  simulateDisconnect() {
    this._loggerService.debug("To simulate disconnect, disconnecting web socket");
    this._socketService.disconnectSocket(true);
    this._socketService.setIsNeedReconnectTrue();
    this._socketService.setDisconnectTime();
  }
  simulateReconnect() {
    console.log('To simulate reconnect, connect web socket again')
    this._loggerService.debug("To simulate reconnect, connect web socket again");
    this._socketService.connectToWebSocket(this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_ID), this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN));
  }

  enableDevMode(): void {
    this.isDevMode = true;
  }


  /**
   * Get All intial contents by module
   *
   * @memberof WebclientService
   */
  getAllModuleInitialContent(): void {
    _.each(this._moduleManagerService.modules, (moduleKey) => {
      this.getInitialContentByModuleKey(moduleKey);
    });
  }


  /**
   * Get separate initial content by module, perform all follow up action in module itself
   *
   * @param {string} key
   * @memberof WebclientService
   */
  getInitialContentByModuleKey(key: string): void {
    switch (key) {
      case ModuleKeyDefinition.WATERMARK:
        this._watermarkService.handleInitialWatermarkContent();
        break;
      case ModuleKeyDefinition.QUESTIONNAIRE:
        this._questionnaireService.handleInitialQuestionnaireContent();
        break;
      case ModuleKeyDefinition.STORE_REPORT:
        this._jobReportService.handleInitialStoreReportContent(this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.JOB_DISPATCH));
        break;
      case ModuleKeyDefinition.WORKFLOW:
        this._workflowService.handleInitialWorkflowContent();
        break;
    }
  }

  checkIfEnableMessageReadTicks(): boolean {
    return this.enable_message_read_ticks;
  }

  checkIfEnableMessageStar(): boolean {
    return this.enable_message_star;
  }

  getImportantUsers(callback?: Function): void {
    this._teamnoteApiService.callApi(
      TeamNoteApiConstant.IMPORTANT_USERS.GET,
      {},
      (resp: ImportantUsers) => {
        this._userContactService.storeImportantUsers(resp)

        // sync the vip display changes for chat list
        this._userContactService.updateImportantUsers(this._userContactService.getImportantUsers())

        if (callback) {
          callback()
        }
      },
      (err) => {
        this._tnNotificationService.showSystemError();
      }
    )
  }

  checkIfEnableImportantUsers(): boolean {
    return this.enable_important_users;
  }

  checkIfEnableMessageDelete(): boolean {
    return this.enable_message_delete;
  }

  updateHasBeenActive(): void {
    this.hasBeenActive = true;
  }
}
